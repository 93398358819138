import React from "react"
import styles from "./clients.module.css"

import imageTemple from "../images/clients/temple-university.png"
import imageBannerHealth from "../images/clients/banner-health.png"
import imageQatar from "../images/clients/qatar-airways.png"

import imageBarts from "../images/clients/barts.png"
import imageTrusted from "../images/clients/trusted.png"
import imageNational from "../images/clients/national.png"
import imageEnlistedHeroes from "../images/clients/enlisted-heroes.png"

import oculusisvpartnerSVG from "../images/oculus_isv_partner_black.svg"


import Slider from "react-slick"
import { SimpleLayout } from "./layout"

const Clients = props => {
  const settings3 = {
    arrows: false,
    slidesToShow: 3,
    dots: true,
    autoplay: true,
    infinite:true
  }
  const settings = {
    arrows: false,
    slidesToShow: 1,
    dots: true,
    autoplay: true,
    infinite:true
  }

  return (
    <SimpleLayout style={{ background: "white" }}>
      <div className={styles.clients}>
        {/* <h1>Clients</h1> */}
        {props.children}
        <div className={styles.clientList}>
          <img src={imageTemple} alt="" />
          <img src={imageBannerHealth} alt="" />
          <img src={imageQatar} alt="" />
          <img src={imageBarts} alt="" />
          <img src={imageTrusted} alt="" />
          <img src={imageNational} alt="" />
        </div>

        <div className={styles.carousel3}>
          <Slider {...settings3}>
            <div>
              <img src={imageTemple} alt="" style={{ marginTop: "70px" }} />
            </div>
            <div>
              <img src={imageBannerHealth} alt="" />
            </div>
            <div>
              <img src={imageQatar} alt="" style={{ marginTop: "60px" }} />
            </div>
            {props.partners && <div>
              <img src={imageBarts} alt="" />
            </div>}
            <div>
              <img src={imageTrusted} alt="" style={{ marginTop: "60px" }} />
            </div>
            <div>
              <img src={imageNational} alt="" />
            </div>
            <div>
              <img src={imageEnlistedHeroes} alt="" />
            </div>
          </Slider>
        </div>

        <div className={styles.carousel}>
          <Slider {...settings}>
            <div>
              <img src={imageTemple} alt="" style={{ marginTop: "70px" }} />
            </div>
            <div>
              <img src={imageBannerHealth} alt="" />
            </div>
            <div>
              <img src={imageQatar} alt="" style={{ marginTop: "60px" }} />
            </div>
            {props.partners && <div>
              <img src={imageBarts} alt="" />
            </div>}
            <div>
              <img src={imageTrusted} alt="" style={{ marginTop: "60px" }} />
            </div>
            <div>
              <img src={imageNational} alt="" />
            </div>
            <div>
              <img src={imageEnlistedHeroes} alt="" />
            </div>

          </Slider>
        </div>
        <div style={{ marginTop: "60px" }}>
          <hr/>
          <div style={{marginTop: "50px",display: "flex", alignItems:"center",justifyContent:"center"}}>
          <p style={{ fontWeight:"bold",fontSize:"1.1em",color:"#444", marginTop: "10px",marginRight:"20px" }}>Dual Good Health is an official</p>
          <div>
          <img src={oculusisvpartnerSVG} alt="" />
          </div>
          </div>
        </div>
      </div>
    </SimpleLayout>
  )
}

export default Clients
